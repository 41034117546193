import { useUser } from '@/providers/user';
import { Bell, ChevronsUpDown } from 'lucide-react';
import { useNotifications } from '@/lib/hooks/use-notifications';
import { NotificationsPopover } from '@/features/company/components/views/notifications/notifications-popover';
import { AccountPopover } from './views/account';
import { Avatar } from './avatar';
import { useCompany } from '../providers/company';
import { SidebarTrigger } from '@/components/ui/sidebar';
import { useVendor } from '@/features/company/hooks/use-vendor';

export const CompanyHeader: React.FC = () => {
  const { user } = useUser('CompanyHeader');
  const { activeCompany: company } = useCompany('CompanyHeader');
  const [vendor] = useVendor();

  const { unreadCount } = useNotifications('AppHeader');

  return (
    <div className='flex w-full px-4 py-1'>
      {/* <p className="hidden md:block text-sky-950 xl:mx-12 mt-2 ">Overview</p> */}
      <div className='flex items-center gap-4'>
        <SidebarTrigger />
      </div>
      <div className='flex gap-8 items-center ml-auto'>
        <NotificationsPopover>
          <span className='relative text-muted-foreground cursor-pointer'>
            <Bell width={24} height={24} />
            {unreadCount > 0 && (
              <span className='px-1.5 rounded-full absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 flex items-center justify-center text-xs bg-danger text-danger-foreground border border-background'>
                {unreadCount}
              </span>
            )}
          </span>
        </NotificationsPopover>
        { vendor?.disable ? 
          <div className='flex items-center gap-2 cursor-pointer'>
            <div className='hidden md:block'>
              <div className='text-base leading-4 font-medium'>{user?.fullName}</div>
              <div className='text-xs capitalize text-muted-foreground'>{company?.name}</div>
            </div>
            <Avatar name={user.fullName} />
            <ChevronsUpDown className='w-4 h-4 hidden md:block' />
          </div>
         :
        <AccountPopover>
        <div className='flex items-center gap-2 cursor-pointer'>
          <div className='hidden md:block'>
            <div className='text-base leading-4 font-medium'>{user?.fullName}</div>
            <div className='text-xs capitalize text-muted-foreground'>{company?.name}</div>
          </div>
          <Avatar name={user.fullName} />
          <ChevronsUpDown className='w-4 h-4 hidden md:block' />
        </div>
      </AccountPopover>}
      </div>
    </div>
  );
};
